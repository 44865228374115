import * as Api from '@/api/index/index'
import { switchCategory, formatMoment, resolveAddress } from '@/utils/utils'
import ActivitySchedule from '@/modules/ActivitySchedule/ActivitySchedule.vue'
import scoreSwiper from '@/views/Index/comonents/scoreSwiper.vue'
import materialAndArticle from '@/views/Index/comonents/materialAndArticle.vue'
import series from '@/modules/CategoryIndex/components/series.vue'
import adList from '@/views/Index/comonents/adList.vue'
import { adsPosition } from '@/enums/API'
import { ads } from '@/api/common/common'
import { useEventBus } from '@/utils/bus.js'
import Slideshow from '@/components/Slideshow/Slideshow.vue'
export default {
    name: 'CategoryIndex',
    data() {
        return {
            isShowQrcode: false,
            categoryData: [],
            qrImage: '',
            categoryId: 0,
            category: 'gre',
            isShowSubMenu: false,
            menuList: [],
            swiperData: [],
            seriesData: [],
            adsData: {},
            adsPosition,
            downDesc: '· 最新雅思口语素材在线练习  每个话题多个素材 外教编写并录音\n' +
                '· 最新雅思写作素材 这是一条文字 这是一条文字\n' +
                '· 雅思全科考试预测 这是一条文字 这是一条文字\n',
            adsDataList: '',
            adsOnlineExer: [], // 在线练习
            eventBus:undefined
        }
    },
    created() {
        this.eventBus = useEventBus()
        this.categoryId = switchCategory(this.$route.path)
        this.eventBus.$on('busAdCallback',(obj)=>{
            this.adsData = obj
            this.downloadAds()
            this.onlineExerAds()
            this.$refs.slideshowRef?.init(obj[adsPosition.MAIN_CLASSIFY_BANNER] )
        })
    },
    mounted() {
        let path = this.$route.path
        this.category = path.split('/')[1]
        this.menuList = this.ieltsMenu
        this.getSwiperData()
        this.getSeriesList()
        this.init()
    },
    methods: {
        async init() {
            this.getData()
        },
        getData() {
            this.getAds([adsPosition.MOCK_EXAM, adsPosition.MAIN_BANNER, adsPosition.SIDE_BANNER, adsPosition.HOMEPAGE_BOTTOM, adsPosition.MATERIAL_SPEAKING, adsPosition.DOWNLOAD_APP, adsPosition.OnlineExerAds, adsPosition.MAIN_CLASSIFY_BANNER])
        },
        getCache() {
            this.adsData = JSON.parse(localStorage.adsData)
            this.articleSwiperData = JSON.parse(localStorage.articleSwiperData)
        },
        setCache(key, value) {
            localStorage.setItem(key, JSON.stringify(value))
        },
        getAds(position) {
            this.eventBus.$emit('busGetAd', { position: position, category_id: this.categoryId })
        },
        handleShowMenu(category) {
            this.isShowSubMenu = true
            switch (category.categoryId) {
                case 1:
                    return this.menuList = this.ieltsMenu
            }
        },
        getSwiperData() {
            const params = {
                article_type_id: 1,
                category_id: this.categoryId
            }
            Api.articleParameter(params, res => {
                this.swiperData = res
            })
        },
        getSeriesList() {
            Api.caregorySeries(this.categoryId, res => {
                this.seriesData = res
            })
        },
        callService() {
            this.categoryData = this.$store.state.common.categoryTypes
            this.categoryData.forEach(item => {
                if (item.abbr?.toUpperCase() === this.category.toUpperCase()) {
                    this.isShowQrcode = true
                    this.qrImage = item.qrcode_image_url
                }
            })
        },
        delQrcodeBox() {
            this.isShowQrcode = false
        },
        downloadAds() {
            this.adsDataList = this.adsData[adsPosition.DOWNLOAD_APP][0]
        },
        // 在线练习广告位
        onlineExerAds() {
            this.adsData[adsPosition.OnlineExerAds] = this.adsData[adsPosition.OnlineExerAds] || []
            this.adsOnlineExer =  this.adsData[adsPosition.OnlineExerAds].slice(0, 4)
        },
        // 在线练习点击跳转
        onlineBtn(res) {
            window.open(res.link_url)
        }
    },
    components: { ActivitySchedule, scoreSwiper, materialAndArticle, series, adList, Slideshow }
}
