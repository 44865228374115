<template>
  <div class="module-category-index">
<!--    <Slideshow class="slideshow" ref="slideshowRef"></Slideshow>-->
    <!-- 在线练习跳转按钮 -->
    <div class="section section-exercise" v-if="adsOnlineExer.length">
      <div class="section-title">在线练习</div>
      <div class="section-container">
        <div class="box" v-for="item in adsOnlineExer" :key="item.id">
          <div class="content-box" @click="onlineBtn(item)">
            <div class="icon-pig">
              <img :src="item.image_url" alt="">
            </div>
            <div class="title-text">{{item.title + item.subtitle}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- ------ -->
    <activity-schedule class="section section-activity-schedule" :category-id="categoryId" />
    <series class="section" :series-data="seriesData" v-if="seriesData.length > 0"/>
    <div class="section section-score" v-if="swiperData.length > 0">
      <div class="section-top">
        <div class="section-title">学员优秀备考出分案例</div>
      </div>
      <div class="section-container">
        <score-swiper :swiper-data="swiperData"></score-swiper>
        <div class="service-button-box">
          <div class="service-button" @click="callService">定制专属规划 <span>立即点击咨询</span></div>
        </div>
        <div class="qr-code" v-show="isShowQrcode">
          <div class="close-btn iconfont icon-papa-close-small" @click="delQrcodeBox"></div>
          <div class="title">立即咨询</div>
          <img class="qr-image" :src="qrImage" alt="">
          <div class="tip">微信扫码添加小助手，咨询课程</div>
        </div>
      </div>
    </div>
    <material-and-article class="section section-material-article" position="category" :category="category" :category-id="categoryId"/>
    <div class="section section-ad" v-if="adsData[adsPosition.MATERIAL_SPEAKING]?.length>0">
      <div class="section-title-hight">高效备考工具</div>
      <ad-list :ad-data="adsData[adsPosition.MATERIAL_SPEAKING]"></ad-list>
    </div>
    <div class="section section-ad"  v-if="adsData[adsPosition.MOCK_EXAM]?.length>0">
      <div class="section-title">仿真模考中心</div>
      <div class="section-container">
        <ad-list :ad-data="adsData[adsPosition.MOCK_EXAM]"></ad-list>
      </div>
    </div>
    <div class="section section-download" v-if="adsDataList">
      <div class="section-title">APP下载</div>
      <div class="section-container">
        <div class="qrcode">
          <img :src="adsDataList.image_url" alt="">
        </div>
        <div class="right">
          <div class="desc" v-html="adsDataList.title">
          </div>
          <div class="desc" v-html="adsDataList.subtitle">
          </div>
          <a :href="adsDataList.link_url" class="button">免费下载</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import CategoryIndex from './CategoryIndex'

  export default CategoryIndex
</script>

<style lang="less">
    .slideshow{
      margin: 20px 0 30px 0;
    }
    .qr-code {
      width: 400px;
      height: 360px;
      background: #fff;
      box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);
      border-radius: 5px;
      position: fixed;
      top: 350px;
      left: 50%;
      margin-top: -180px;
      margin-left: -200px;
      z-index: 10000;

      & > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      & > .title {
        margin-top: 36px;
        font-size: 24px;
        color: #333;
        font-weight: bold;
      }

      & > .qr-image {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin-bottom: 26px;
      }

      & > .tip {
        font-size: 18px;
        color: #333;
      }

      & > .reply {
        font-size: 16px;
        color: #666;
        margin-top: 8px;
      }
    }
  .module-category-index {
    width: 1200px;
    margin: 20px auto 0;
    min-height: 100vh;

    & > .section {
      margin-bottom: 60px;

      .section-title {
        box-sizing: border-box;
        padding-left: 20px;
        width: 12em;
        text-align: left;
        flex-shrink: 0;
        font-size: 24px;
        font-weight: bold;
        color: #000;
        margin-bottom: 30px;
      }
      .section-title-hight {
        box-sizing: border-box;
        padding-left: 20px;
        text-align: left;
        flex-shrink: 0;
        font-size: 24px;
        font-weight: bold;
        color: #000;
        margin-bottom: 30px;
        margin-right: 30px;
      }
    }

    & > .section-score {
      box-sizing: border-box;

      .section-title {
        box-sizing: border-box;
        padding-left: 20px;
        width: 12em;
        text-align: left;
        flex-shrink: 0;
        font-size: 24px;
        font-weight: bold;
        color: #000;
      }

      .section-container {
        box-sizing: border-box;
        padding: 20px;
        width: 100%;
        height: 418px;
        border-radius: 28px;
        background: #fff;

        .service-button-box {
          width: 100%;
          display: flex;
          justify-content: center;

          .service-button {
            width: 300px;
            height: 60px;
            border-radius: 30px;
            background: #29d087;
            color: #fff;
            font-size: 16px;
            line-height: 60px;
            cursor: pointer;

            span {
              margin-left: 5px;
              color: #FFF441;
              font-size: 20px;
            }
          }
        }
      }
    }

    & > .section-download {
      .section-container {
        box-sizing: border-box;
        width: 1200px;
        height: 240px;
        background: #FFFFFF;
        border-radius: 28px;
        display: flex;
        justify-content: space-between;
        padding: 30px;

        .qrcode {
          box-sizing: border-box;
          padding: 10px;
          width: 180px;
          height: 179px;
          border-radius: 20px;
          border: 1px solid #f0f0f0;
          overflow: hidden;
          margin-right: 42px;

          img {
            width: 100%;
          }
        }

        .right {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-around;

          .desc {
            // white-space: break-spaces;
            white-space: pre-wrap;
            text-align: left;
          }

          .button {
            display: inline-block;
            width: 132px;
            height: 42px;
            background: #29D087;
            border-radius: 21px;
            text-align: center;
            line-height: 42px;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #FFFFFF;
          }
        }
      }
    }
    & > .section-exercise {
      .section-container {
        box-sizing: border-box;
        width: 1200px;
        height: 120px;
        background: #FFFFFF;
        border-radius: 28px;
        display: flex;
        justify-content: space-around;
        .box {
          width: 299px;
          height: 120px;
          display: flex;
          justify-content: center;
          align-items: center;
          .content-box {
            // width: 144px;
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:hover {
              cursor: pointer;
            }
            .icon-pig {
              width: 60px;
              height: 60px;
              margin-right: 15px;
              img {
                width: 100%;
                height: 100%;
              }
            }
            .title-text {
              // width: 64px;
              font-size: 16px;
              text-align: left;
              font-weight: 700;
              font-family: SimHei;
              color: rgba(0,0,0,0.9);
              white-space: pre-wrap;
            }
          }
        }
      }
    }
  }
</style>
