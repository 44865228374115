<template>
  <div class="slide-show-box">
    <div class="slide-show">
      <swiper ref="slideSwiper" :options="swiperOptions"
        @mouseenter.native.stop="mouseOverSlider"
        @mouseleave.native.stop="mouseLeaveSlider" 
      >
        <swiper-slide v-for="(slide, index) in bannerList" :key="index" >
          <div class="image-box" :data-v="JSON.stringify(slide)" >
            <img :src="slide.image_url" alt="" :data-v="JSON.stringify(slide)">
          </div>
        </swiper-slide>
        <div
          class="swiper-button-prev swiper-button-white"
          slot="button-prev"
        >
        </div>
        <div
          class="swiper-button-next swiper-button-white"
          slot="button-next">
        </div>
        
      </swiper>
      <div class="swiper-pagination"></div>
    </div>
  </div>
</template>

<script>

  export default {
    name: 'NavBar',
    data () {
      return {
        swiperOptions: {
          loop: true, // 无缝
          autoplay: true,
          paginationClickable: true,
          slidesPerView: 1,
          spaceBetween: 0,
          observer: true,
          observeParents: true,
          // 如果需要分页器
          pagination: {
              el: '.swiper-pagination',
              clickable: true, // 分页器可以点击
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          on:{
            click: (e)=>{
              let attribute = e.target.getAttribute('data-v')
              if(attribute){
                let obj = JSON.parse(attribute)
                this.turnPageTo(obj)
              }
              
            }
          }
        },
        swiper: null,
        bannerList:[]

      }
    },
    mounted () {
      this.$nextTick(() => {
        this.swiper = this.$refs.slideSwiper.$swiper
      })
    },

    methods: {
      init(bannerList){
        this.bannerList = bannerList || []
        this.swiper.reLoop()
      },
      turnPageTo ( item ) {
        _hmt.push(['_trackEvent', 'home_page_ads', 'click', item.title])
        if (item.address !== '') {
          if (item.address.indexOf('http') > -1) {
            window.open(item.address)
          } else {
            window.location.href = item.address
          }
        }
      },
      mouseOverSlider () {
        this.swiper && this.swiper.autoplay.stop()
      },
      mouseLeaveSlider () {
        this.swiper && this.swiper.autoplay.start()
      },
    }
  }
</script>

<style lang="less" scoped>
  .slide-show-box{
    width: 100%;
  }
  .slide-show{
    width: 1200px;
    position: relative;
    margin: 0 auto;
    border-radius: 20px;
    max-height: 326px;
    overflow: hidden;
    .image-box{
      width: 100%;
      cursor: pointer;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .swiper-button-next,
  .swiper-container-rtl, .swiper-button-prev {
    top: 154px;
    right: 20px;
    color: #fff;
    text-align: left;
    width: 40px;
    height: 40px;
    background: rgba(0, 0, 0, 0.2);
    text-align: center;
    line-height: 80px;
    border-radius: 50%;

  }

  .swiper-button-white:after {
    font-size: 16px;
    font-weight: bold;
  }

  .swiper-button-next:hover, .swiper-button-prev:hover {
    color: #29d087;
  }
  .swiper-pagination{
    position: absolute;
    bottom: 20px;
    right: 20px;
    /deep/ .swiper-pagination-bullet{
      background: rgba(255, 255, 255, 0.5) !important;
      margin-right: 6px;
    }
    /deep/ .swiper-pagination-bullet-active{
      width: 16px;
      border-radius: 20px;
      background:  #fff !important;
    }
  }
</style>
