const eventStack = {};

const $on = (eventName, callBack) => {
  if (eventStack[eventName]) {
    eventStack[eventName].callBacks.push(callBack);
    callBack(eventStack[eventName].value);
  } else {
    const evnetBusItem = {
      value: '',
      callBacks: [callBack]
    };
    eventStack[eventName] = evnetBusItem;
  }
};

const $emit = (eventName, value) => {
  if (eventStack[eventName] && eventStack[eventName].callBacks.length) {
    eventStack[eventName].value = value;
    eventStack[eventName].callBacks.forEach(function(callBack) {
      callBack(value);
    });
  }
};

const $off = (eventName) => {
  if (eventStack[eventName]) {
    eventStack[eventName] = null;
  }
};

const eventBus = {
  $emit,
  $on,
  $off
};

export const useEventBus = () => {
  return eventBus;
};