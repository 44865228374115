<template>
  <div class="series">
    <div class="section-title">近期开课课程优选</div>
    <div class="series-list">
      <div class="series-item" v-for="series in seriesData" :key="series.id">
        <div class="left">
          <img v-lazy="series.image_url" alt="">
        </div>
        <div class="right">
          <div class="head">
            <div><span>{{ series.name }}·{{ series.summary }}</span></div>
            <div class="more">
              <div class="service" @click="callService">立即咨询</div>
              <router-link :to="`/${category}/series?${series.id}`">了解更多 <i class="iconfont icon-papa-coupon_arrow"></i>
              </router-link>
            </div>
          </div>
          <div class="spu-list">
            <template v-for="(spu,index) in series.spus">
              <router-link class="spu-item"
                           v-if="index<2"
                           :key="spu.id"
                           :to="`/course/${spu.id}/${spu.default_sku_id}`"
              >
                <div class="spu-img">
                  <img v-lazy="spu.cover_image_url" alt="">
                </div>
                <div class="spu-name">{{spu.name}}</div>
                <div class="spu-tips">{{ spu.tips }}</div>
              </router-link>
            </template>
          </div>
        </div>
      </div>
        <div class="qr-code" v-show="isShowQrcode">
          <div class="close-btn iconfont icon-papa-close-small" @click="delQrcodeBox"></div>
          <div class="title">立即咨询</div>
          <img class="qr-image" :src="qrImage" alt="">
          <div class="tip">微信扫码添加小助手，咨询课程</div>
        </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
  export default {
    name: 'series',
    props: {
      seriesData: {
        type: Array,
        default: () => {
          return []
        }
      }
    },
    computed: {
      ...mapState(['categoryTypes'])
    },
    data(){
      return{
        category: 'ielts',
        isShowQrcode: false,
        categoryData: [],
        qrImage: ''
      }
    },
    mounted () {
      let path = this.$route.path
      this.category = path.split('/')[ 1 ]
      console.log()
    },
    methods: {
      callService() {
        this.categoryData = this.$store.state.common.categoryTypes
        this.categoryData.forEach(item => {
          if(item.abbr === this.category.toUpperCase()) {
            this.isShowQrcode = true
            this.qrImage = item.qrcode_image_url
          }
        })       
      },
      delQrcodeBox() {
        this.isShowQrcode = false
      }
    }
  }
</script>

<style lang="less" scoped>
    .qr-code {
      width: 400px;
      height: 360px;
      background: #fff;
      box-shadow: 0px 13px 46px 0px rgba(86, 86, 86, 0.05);
      border-radius: 5px;
      position: fixed;
      top: 350px;
      left: 50%;
      margin-top: -180px;
      margin-left: -200px;
      z-index: 10000;

      & > .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 20px;
        cursor: pointer;
      }

      & > .title {
        margin-top: 36px;
        font-size: 24px;
        color: #333;
        font-weight: bold;
      }

      & > .qr-image {
        width: 180px;
        height: 180px;
        border-radius: 5px;
        margin-bottom: 26px;
      }

      & > .tip {
        font-size: 18px;
        color: #333;
      }

      & > .reply {
        font-size: 16px;
        color: #666;
        margin-top: 8px;
      }
    }
  
  .series {
    width: 100%;

    .series-list {
      //height: 420px;
      background: #FFFFFF;
      border-radius: 20px;

      .series-item {
        box-sizing: border-box;
        padding: 30px;
        display: flex;
        justify-content: space-between;

        .left {
          width: 330px;
          height: 360px;
          margin-right: 30px;
          background: #F0F0F0;
          border-radius: 16px;
          display: flex;
          align-items: center;
          overflow: hidden;

          img {
            width: 100%;
          }
        }

        .right {
          flex: 1;

          .head {
            display: flex;
            justify-content: space-between;
            margin-bottom: 20px;
            font-size: 20px;
            color: #333;
            font-weight: bold;
            .more{
              display: flex;
              align-items: center;
              .service{
                width: 96px;
                height: 34px;
                background: #FFFFFF;
                border: 1px solid #29D087;
                border-radius: 17px;
                color: #29d087;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #29D087;
                line-height: 34px;
                margin-right: 10px;
              }
              a {
                font-size: 12px;
                color: #666;

                i {
                  font-size: 12px;
                }
              }
            }

          }

          .spu-list {
            display: flex;
            justify-content: space-between;

            .spu-item {
              box-sizing: border-box;
              padding: 20px;
              width: 380px;
              height: 306px;
              background: #FFFFFF;
              border: 1px solid #E6E6E6;
              border-radius: 20px;
              display: flex;
              justify-content: space-between;
              flex-direction: column;
              text-align: left;
              cursor: pointer;
              .spu-img {
                width: 340px;
                height: 188px;
                background: #F0F0F0;
                border-radius: 8px;
                display: flex;
                align-items: center;
                overflow: hidden;

                img {
                  width: 100%;
                }
              }
              .spu-name{
                font-size: 16px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #000000;
                line-height: 40px;
              }
              .spu-tips{
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #666666;
                line-height: 40px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }

            }
          }
        }
      }

    }
  }
</style>